import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import {
  Box,
  Button,
  Flex,
  useToast,
  Progress,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Introduction,
  RoleInterest,
  Experience,
  PaymentPreference,
  ServiceTimeline,
  FinalScreen,
} from "./Forms";
import { updateSignUpQuestions } from "../../API/user";

function MultiSteps({ onComplete }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  

  useEffect(() => {
    // Check if the form is already completed
    const hasCompletedForm = localStorage.getItem("hasCompletedForm");

    if (hasCompletedForm=='false') {
      // Open the modal if the form has not been completed
      onOpen();
    }
  }, [onOpen]);

  return (
    <Modal isOpen={isOpen} onClose={() => { /* Prevent closing */ }} size="lg">
      <ModalOverlay />
      <ModalContent>
        <Multi onClose={() => {
          onComplete(); // Call the callback to update state in AdminDashboard
        }} />
      </ModalContent>
    </Modal>
  );
}



function Multi({ onClose }) {
  const [step, setSteps] = useState(1);
  const totalSteps = 6;

  const [selections, setSelections] = useState({
    introduction: "",
    roleInterest: "",
    experience: "",
    paymentPreference: "",
    serviceTimeline: "",
  });

  const toast = useToast();

  function handlePrev() {
    if (step > 1) setSteps((prevStep) => prevStep - 1);
  }

  function handleNext() {
    const currentStepKey = getStepKey(step);
    if (!selections[currentStepKey]) {
      toast({
        title: "Selection Required",
        description: "Please select an option before proceeding.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
        variant: "solid",
      });
      return;
    }
    if (step < totalSteps) setSteps((prevStep) => prevStep + 1);
  }

  function getStepKey(step) {
    switch (step) {
      case 1:
        return "introduction";
      case 2:
        return "roleInterest";
      case 3:
        return "experience";
      case 4:
        return "paymentPreference";
      case 5:
        return "serviceTimeline";
      default:
        return "";
    }
  }

  const handleOptionChange = (value) => {
    const currentStepKey = getStepKey(step);
    setSelections((prevSelections) => ({
      ...prevSelections,
      [currentStepKey]: value,
    }));
  };

  const navigate = useNavigate();
  const handleFormCompletion = async () => {

  
    try {
      console.log('User sign-up questions saved:', selections);
  
      // Save questions to the database
      const Response = await updateSignUpQuestions(selections);
  
      // Mark the form as completed
      localStorage.setItem("hasCompletedForm", "true");
  
      // Get the existing user data from localStorage (and parse it)
      const user = JSON.parse(localStorage.getItem('user'));
  
      // Update the user data with the completed sign-up questions
      const updatedUser = {
        ...user,
        userSignUpQuestions: selections // Use selections for the questions
      };
  
      // Save the updated user data back to localStorage (stringify it)
      localStorage.setItem('user', JSON.stringify(updatedUser));
  
      // Close the modal
      onClose(); 
  
      // Navigate to the /jobs page after closing the modal
      navigate('/jobs');
    } catch (error) {
      console.error('Error saving user sign-up questions:', error);
    }
  };
  
  
  

  const renderSteps = () => {
    switch (step) {
      case 1:
        return (
          <Introduction selectedOption={selections.introduction} onOptionChange={handleOptionChange} />
        );
      case 2:
        return (
          <RoleInterest selectedOption={selections.roleInterest} onOptionChange={handleOptionChange} />
        );
      case 3:
        return (
          <Experience selectedOption={selections.experience} onOptionChange={handleOptionChange} />
        );
      case 4:
        return (
          <PaymentPreference selectedOption={selections.paymentPreference} onOptionChange={handleOptionChange} />
        );
      case 5:
        return (
          <ServiceTimeline selectedOption={selections.serviceTimeline} onOptionChange={handleOptionChange} />
        );
      case 6:
        return <FinalScreen />;
      default:
        return null;
    }
  };

  return (
    <>
      <ModalHeader>
        <Heading as="h2" size="lg">
          Step {step} of {totalSteps}
        </Heading>
      </ModalHeader>
      <ModalBody>
        <Progress colorScheme="green" value={(step / totalSteps) * 100} mb={6} />
        {renderSteps()}
      </ModalBody>
      <ModalFooter>
        <Flex justify="space-between" width="100%">
          <Button onClick={handlePrev} isDisabled={step === 1} colorScheme="whatsapp">
            Prev
          </Button>
          <Button
            onClick={step === totalSteps ? handleFormCompletion : handleNext}
            colorScheme="whatsapp"
          >
            {step === totalSteps ? "Create a job" : "Next"}
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
}

export default MultiSteps;
