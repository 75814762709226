import React, { useState, useEffect } from "react";
import { SimpleGrid, Text, useColorModeValue, Button, Box, useToast } from "@chakra-ui/react";
import { Card } from "./Card";
import { Information } from "./Information";
import { updateUser } from "../../../../API/userprofile";
import { FiUser, FiBriefcase, FiBarChart2, FiDollarSign, FiCalendar } from 'react-icons/fi';
import { Icon } from "@chakra-ui/react";



export function GeneralInformation(props) {
  const { ...rest } = props;
  const [isEditing, setIsEditing] = useState({
    name: false,
    email: false,
    phoneNumber: false,
    skills: false
  });
  const [editValues, setEditValues] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    skills: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null); 
  const [userSignUpQuestions, setUserSignUpQuestions] = useState(null); // New state for userSignUpQuestions
  const toast = useToast();

  useEffect(() => {
    // Load user data from local storage
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
      setEditValues({
        name: storedUser.userName,
        email: storedUser.email,
        phoneNumber: storedUser.phoneNumber,
        skills: storedUser.skills
      });
      // Load userSignUpQuestions if available
      const storedSignUpQuestions = typeof storedUser.userSignUpQuestions === "string"
        ? JSON.parse(storedUser.userSignUpQuestions)
        : storedUser.userSignUpQuestions;
        
      if (storedSignUpQuestions) {
        setUserSignUpQuestions(storedSignUpQuestions);
      }
    } else {
      console.error("No user data found in local storage.");
    }
  }, []);
  

  const handleEdit = (field) => {
    setIsEditing(prevState => ({
      ...prevState,
      [field]: true
    }));
  };

  const handleChange = (e, field) => {
    setEditValues(prevValues => ({
      ...prevValues,
      [field]: e.target.value
    }));
  };

  const handleSave = async () => {
    if (!user) return; // Early exit if user is not available

    setIsLoading(true);
    try {
      // Update user information
      await updateUser(user.userId, editValues);

      // Update local storage
      const updatedUser = {
        ...user,
        userName: editValues.name,
        email: editValues.email,
        phoneNumber: editValues.phoneNumber,
        skills: editValues.skills
      };
      localStorage.setItem('user', JSON.stringify(updatedUser));
      
      toast({
        title: "Update Successful.",
        description: "Your information has been updated.",
        status: "success",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });

      setUser(updatedUser);
    } catch (error) {
      toast({
        title: "Update Failed.",
        description: "There was an error updating your information.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      setIsEditing({
        name: false,
        email: false,
        phoneNumber: false,
        skills: false
      });
    }
  };

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const colorbg = useColorModeValue("#FAFAF7", "#FAFAF7");
  const textColorSecondary = "gray.400";
  const bgSurvey = useColorModeValue("gray.100", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  if (!user) return <Text>Loading...</Text>; // Handle loading state

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'
      >
        General Information
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        Welcome to your profile! Here, you'll find a snapshot of your essential information and key details. From personal insights to professional achievements, this space is designed to keep you informed and empowered.
      </Text>
      <SimpleGrid columns='2' gap='20px'>
        <Information
          boxShadow={cardShadow}
          title='Name'
          value={editValues.name}
          isEditing={isEditing.name}
          onEdit={() => handleEdit('name')}
          onChange={(e) => handleChange(e, 'name')}
        />
        <Information
          boxShadow={cardShadow}
          title='Email'
          value={editValues.email}
          isEditing={isEditing.email}
          onEdit={() => handleEdit('email')}
          onChange={(e) => handleChange(e, 'email')}
        />
        <Information
          boxShadow={cardShadow}
          title='Contact No.'
          value={editValues.phoneNumber}
          isEditing={isEditing.phoneNumber}
          onEdit={() => handleEdit('phoneNumber')}
          onChange={(e) => handleChange(e, 'phoneNumber')}
        />
        {user.role === 'ADMIN' && (
          <Information
            boxShadow={cardShadow}
            title='Role'
            value={user.role}
          />
        )}
        {user.role === 'BILLER' && (
          <Information
            boxShadow={cardShadow}
            title='Skills'
            value={editValues.skills}
            isEditing={isEditing.skills}
            onEdit={() => handleEdit('skills')}
            onChange={(e) => handleChange(e, 'skills')}
          />
        )}
      </SimpleGrid>
      <Box mt={4} textAlign="right">
        <Button
          colorScheme="whatsapp"
          onClick={handleSave}
          isLoading={isLoading}
          loadingText="Saving"
        >
          Save Changes
        </Button>
      </Box>

      {/* Survey Questions Section */}
      {user.role !== 'ADMIN' && user.role !== 'BILLER' && (
     
      <Box mt={8}>
        <Text
          color={textColorPrimary}
          fontWeight='bold'
          fontSize='2xl'
          mt='10px'
          mb='4px'
        >
          Survey Responses
        </Text>
        <SimpleGrid 
  columns={{ base: 1, md: 2 }} // One column on smaller screens, two on larger
  gap={6} 
  py={6} 
  px={8} 
  bg={colorbg}  
  borderRadius="lg" 
  boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)" 
  border="1px solid" 
  borderColor="gray.200"
>
  {/* Introduction Section */}
  <Box 
    bg={colorbg}  // Very light grey background
    p={6} 
    borderRadius="lg" 
    boxShadow="0px 2px 8px rgba(0, 0, 0, 0.05)"
    border="1px solid" 
    borderColor="gray.300"
  >
    <Text fontWeight="bold" fontSize="xl" color="black">
      <Icon as={FiUser} mr={2} color="teal.500" /> {/* Adding color to the icon */}
      Introduction:
    </Text>
    <Text fontSize="md" color="black" mt={1} lineHeight="1.6" ml={8}> {/* Moved to the right */}
      {userSignUpQuestions?.introduction || "Not available"}
    </Text>
    <hr style={{ borderColor: "#ddd", marginTop: "10px" }} />
  </Box>

  {/* Role Interest Section */}
  <Box 
    bg={colorbg}  
    p={6} 
    borderRadius="lg" 
    boxShadow="0px 2px 8px rgba(0, 0, 0, 0.05)"
    border="1px solid" 
    borderColor="gray.300"
  >
    <Text fontWeight="bold" fontSize="xl" color="black">
      <Icon as={FiBriefcase} mr={2} color="blue.500" /> {/* Adding color to the icon */}
      Role Interest:
    </Text>
    <Text fontSize="md" color="black" mt={1} lineHeight="1.6" ml={8}> {/* Moved to the right */}
      {userSignUpQuestions?.roleInterest || "Not available"}
    </Text>
    <hr style={{ borderColor: "#ddd", marginTop: "10px" }} />
  </Box>

  {/* Experience Level Section */}
  <Box 
    bg={colorbg}  // Very light grey background
    p={6} 
    borderRadius="lg" 
    boxShadow="0px 2px 8px rgba(0, 0, 0, 0.05)"
    border="1px solid" 
    borderColor="gray.300"
  >
    <Text fontWeight="bold" fontSize="xl" color="black">
      <Icon as={FiBarChart2} mr={2} color="purple.600" /> {/* Adding color to the icon */}
      Experience Level:
    </Text>
    <Text fontSize="md" color="black" mt={1} lineHeight="1.6" ml={8}> {/* Moved to the right */}
      {userSignUpQuestions?.experience || "Not available"}
    </Text>
    <hr style={{ borderColor: "#ddd", marginTop: "10px" }} />
  </Box>

  {/* Payment Preference Section */}
  <Box 
    bg={colorbg}  // Very light grey background
    p={6} 
    borderRadius="lg" 
    boxShadow="0px 2px 8px rgba(0, 0, 0, 0.05)"
    border="1px solid" 
    borderColor="gray.300"
  >
    <Text fontWeight="bold" fontSize="xl" color="black">
      <Icon as={FiDollarSign} mr={2} color="green.500" /> {/* Adding color to the icon */}
      Payment Preference:
    </Text>
    <Text fontSize="md" color="black" mt={1} lineHeight="1.6" ml={8}> {/* Moved to the right */}
      {userSignUpQuestions?.paymentPreference || "Not available"}
    </Text>
    <hr style={{ borderColor: "#ddd", marginTop: "10px" }} />
  </Box>

  {/* Service Timeline Section */}
  <Box 
    bg={colorbg}  // Very light grey background
    p={6} 
    borderRadius="lg" 
    boxShadow="0px 2px 8px rgba(0, 0, 0, 0.05)"
    border="1px solid" 
    borderColor="gray.300"
  >
    <Text fontWeight="bold" fontSize="xl" color="black">
      <Icon as={FiCalendar} mr={2} color="orange.500" /> {/* Adding color to the icon */}
      Service Timeline:
    </Text>
    <Text fontSize="md" color="black" mt={1} lineHeight="1.6" ml={8}> {/* Moved to the right */}
      {userSignUpQuestions?.serviceTimeline || "Not available"}
    </Text>
    <hr style={{ borderColor: "#ddd", marginTop: "10px" }} />
  </Box>
</SimpleGrid>





      </Box>
)}
      
    </Card>
  );
}
