import React from 'react';
import { Box, Heading, Text, RadioGroup, Radio, Stack } from '@chakra-ui/react';

const customRadioStyle = {
  _checked: {
    bg: 'green.400',
    borderColor: 'green.400',
    color: 'white',
  },
  _focus: {
    boxShadow: 'none',
  },
  _hover: {
    bg: 'green.100',
    borderColor: 'green.300',
  },
};

export function Introduction({ selectedOption, onOptionChange }) {
  return (
    <Box>
      <Heading mb={6}>Welcome to Dr. Billerz!</Heading>
      <Text mb={4} ml={1} fontSize="lg">
        We’re excited to learn more about your needs. Please start by selecting the option that best describes you.
      </Text>
      <RadioGroup value={selectedOption} onChange={onOptionChange}>
        <Stack spacing={4}>
          <Radio value="billing-company" sx={customRadioStyle}>
            Billing Company
          </Radio>
          <Radio value="medical-provider" sx={customRadioStyle}>
            Medical Provider
          </Radio>
          <Radio value="medical-group-representative" sx={customRadioStyle}>
            Medical Group Representative
          </Radio>
          <Radio value="job-seeker" sx={customRadioStyle}>
            Job Seeker
          </Radio>
        </Stack>
      </RadioGroup>
    </Box>
  );
}

export function RoleInterest({ selectedOption, onOptionChange }) {
  return (
    <Box>
      <Heading mb={6}>Let’s Get More Specific</Heading>
      <Text mb={4} fontSize="lg">
        Please tell us more about your role or interest.
      </Text>
      <RadioGroup value={selectedOption} onChange={onOptionChange}>
        <Stack spacing={4}>
          <Radio value="billing-company" sx={customRadioStyle}>
            We are a billing company looking to hire or expand our billing team.
          </Radio>
          <Radio value="medical-provider" sx={customRadioStyle}>
            I’m a healthcare provider seeking to hire a medical biller.
          </Radio>
          <Radio value="medical-group" sx={customRadioStyle}>
            I represent a medical group looking for efficient billing solutions.
          </Radio>
          <Radio value="job-seeker" sx={customRadioStyle}>
            I’m a job seeker exploring opportunities in medical billing.
          </Radio>
        </Stack>
      </RadioGroup>
    </Box>
  );
}

export function Experience({ selectedOption, onOptionChange }) {
  return (
    <Box>
      <Heading mb={6}>Your Preferred Experience Level</Heading>
      <Text mb={4} fontSize="lg">
        What level of experience are you seeking in a medical biller?
      </Text>
      <RadioGroup value={selectedOption} onChange={onOptionChange}>
        <Stack spacing={4}>
          <Radio value="entry" sx={customRadioStyle}>
            Entry Level
          </Radio>
          <Radio value="intermediate" sx={customRadioStyle}>
            Intermediate Level
          </Radio>
          <Radio value="advanced" sx={customRadioStyle}>
            Advanced Level
          </Radio>
          <Radio value="not-sure" sx={customRadioStyle}>
            I’m not sure yet.
          </Radio>
        </Stack>
      </RadioGroup>
    </Box>
  );
}

export function PaymentPreference({ selectedOption, onOptionChange }) {
  return (
    <Box>
      <Heading mb={6}>Payment Preferences</Heading>
      <Text mb={4} fontSize="lg">
        How would you prefer to handle payment?
      </Text>
      <RadioGroup value={selectedOption} onChange={onOptionChange}>
        <Stack spacing={4}>
          <Radio value="hourly" sx={customRadioStyle}>
            Hourly Rate
          </Radio>
          <Radio value="fixed-price" sx={customRadioStyle}>
            Fixed Price
          </Radio>
          <Radio value="not-sure" sx={customRadioStyle}>
            Not sure at this moment.
          </Radio>
        </Stack>
      </RadioGroup>
    </Box>
  );
}

export function ServiceTimeline({ selectedOption, onOptionChange }) {
  return (
    <Box>
      <Heading mb={6}>Service Timeline</Heading>
      <Text mb={4} fontSize="lg">
        When do you require these services to begin?
      </Text>
      <RadioGroup value={selectedOption} onChange={onOptionChange}>
        <Stack spacing={4}>
          <Radio value="immediate" sx={customRadioStyle}>
            Immediately
          </Radio>
          <Radio value="within-1-month" sx={customRadioStyle}>
            Within the next month
          </Radio>
          <Radio value="within-3-months" sx={customRadioStyle}>
            Within the next 3 months
          </Radio>
          <Radio value="flexible" sx={customRadioStyle}>
            I’m flexible with the timeline.
          </Radio>
        </Stack>
      </RadioGroup>
    </Box>
  );
}

export function FinalScreen() {
  return (
    <Box>
      <Heading mb={6}>Thank You!</Heading>
      <Text mb={4} fontSize="lg">
        We appreciate you taking the time to provide these details. Our team will review your information and get in touch with you shortly to discuss how we can assist you further.
      </Text>
    </Box>
  );
}
