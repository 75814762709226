import React, { useEffect, useState } from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Box, Divider, Spinner, Badge } from '@chakra-ui/react';
import { IconButton } from '@chakra-ui/react';
import { FaRegBell, FaInfoCircle } from 'react-icons/fa';
import { fetchNotifications, markNotificationAsRead } from '../../../API/notification';

const NotificationsMenu = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [unreadCount, setUnreadCount] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const loadNotifications = async () => {
        try {
            setLoading(true);
            const user = localStorage.getItem('user');
            if (user) {
                const { userId } = JSON.parse(user);

                const data = await fetchNotifications(userId);
                if (data.success) {
                    const unreadNotifications = data.notifications.filter(notification => !notification.read);
                    setNotifications(unreadNotifications);
                    setUnreadCount(unreadNotifications.length);
                } else {
                    setError('Failed to fetch notifications');
                }
            } else {
                setError('User data not found');
            }
        } catch (error) {
            setError('Error handling notifications');
        } finally {
            setLoading(false);
        }
    };

    const markAllNotificationsAsRead = async () => {
        try {
            const unreadNotifications = notifications.filter(notification => !notification.read);
            await Promise.all(
                unreadNotifications.map(notification => markNotificationAsRead(notification.notificationId))
            );
            loadNotifications(); // Refresh after marking as read
        } catch (error) {
            console.error('Error marking notifications as read:', error);
        }
    };

    useEffect(() => {
        loadNotifications();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            loadNotifications();
        }, 15 * 60 * 1000); // 15 minutes interval

        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <Menu
                isOpen={isOpen}
                onOpen={() => {
                    setIsOpen(true);
                    loadNotifications(); // Fetch notifications when menu opens
                }}
                onClose={() => {
                    setIsOpen(false);
                    markAllNotificationsAsRead(); // Mark notifications as read when menu closes
                }}
            >
                <MenuButton
                    as={IconButton}
                    icon={
                        <Box position="relative">
                            <FaRegBell color={unreadCount > 0 ? 'red' : 'black'} />
                            {unreadCount > 0 && (
                                <Badge
                                    colorScheme="red"
                                    variant="solid"
                                    borderRadius="full"
                                    position="absolute"
                                    top="-1"
                                    right="-1"
                                    fontSize="xs"
                                    minWidth="16px"
                                    textAlign="center"
                                >
                                    {unreadCount}
                                </Badge>
                            )}
                        </Box>
                    }
                    aria-label="Notifications"
                    style={{ backgroundColor: "#A4B5C" }}
                    fontSize="xl"
                    bgColor={'#FAFAF7'}
                />

                <MenuList className="menu-list" maxH="300px" overflowY="auto" bg="white">
                    {loading ? (
                        <MenuItem bg="white" _hover={{ bg: 'white' }}>
                            <Spinner size="lg" />
                            <Box ml={2}>Loading...</Box>
                        </MenuItem>
                    ) : error ? (
                        <MenuItem bg="white" _hover={{ bg: 'white' }}>{error}</MenuItem>
                    ) : notifications.length > 0 ? (
                        notifications.map(notification => (
                            <MenuItem key={notification.notificationId} bg="white" _hover={{ bg: 'white' }}>
                                <Box display="flex" alignItems="center" p={2}>
                                    <FaInfoCircle style={{ marginRight: '15px', color: 'green' }} size={20} />
                                    <Box>
                                        <strong>{notification.title}</strong>
                                        <p>{notification.description}</p>
                                        <Divider />
                                    </Box>
                                </Box>
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem bg="white" _hover={{ bg: 'white' }}>No notifications available</MenuItem>
                    )}
                </MenuList>
            </Menu>
        </>
    );
};

export default NotificationsMenu;
