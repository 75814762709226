import axios from "axios";
import { BASE_URL } from "./constants";

export const BASE_URL_FRONTEND = "http://localhost:3001";

export const vatTax = 5 / 100;
const token = localStorage.getItem("token");

const role = localStorage.getItem("role");
const user = JSON.parse(localStorage.getItem("user"));


export const updateSignUpQuestions = async (selections) => {
    try {
        const response = await axios.put(
            `${BASE_URL}/user/sign-up-questions/${user.userId}`,
            {userSignUpQuestions : selections},
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        console.log("inserted in db:",response.data);
        return response.data;
       
    } catch (error) {
        throw error;
    }
};
